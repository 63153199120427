@media (max-width: 899px) {
    .grecaptcha-badge {
        display: none !important;
    }
}

.xapi-iframe {

    @media (max-width: 899px) and (orientation: landscape) {
        padding-bottom: 52.25%
    }

    padding-bottom: 75.25%;

    position: relative;
    height: 0;
    width: 100%;

    iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0
    }
}