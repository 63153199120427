.MuiAppBar-root {
  transition: height ease-in-out 0.4s !important;

  .transition-ease-in-out {
    transition: all ease-in-out 0.4s;
  }

  .menu-nav,
  .nav-logo,
  .nav-logo-mobile,
  .nav-logo img,
  .nav-logo-mobile img {
    transition: all ease-in-out 0.4s;
  }

  .menu-nav {
    .MuiTab-labelIcon {
      @media (min-width: 900px) and (max-width: 974px) {
        font-size: 0.8rem;

        svg {
          font-size: 1rem;
        }
      }
    }
  }

  a {
    &.nav-link {
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: 1.11rem;
      outline: 0;
      border-bottom: solid 2px transparent;
      margin: 0 22px;
      border-radius: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      -webkit-text-decoration: none;
      text-decoration: none;
      line-height: 1.25;
      padding: 10px 2px;
      white-space: normal;
      text-align: center;
      color: #111;
      transition: all ease-in-out 0.2s;
      border: none;
      position: relative;

      @media (min-width: 900px) and (max-width: 974px) {
        margin: 0 15px;
        font-size: 0.1.3rem;
      }

      @media (min-width: 975px) and (max-width: 1100px) {
        margin: 0 8px;
      }

      &:hover {
        border: none;

        &::after {
          width: 100%;
          left: 0;
        }
      }

      &.selected {
        &::after {
          width: 100%;
          left: 0;
        }
      }

      &::after {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        left: 50%;
        bottom: 1px;
        background-color: red;
        transition: all ease-in-out 0.2s;
      }
    }
  }
}

#confirm-email-alert {
  .MuiFormControl-root {
    .MuiInputLabel-root {
      line-height: 1.375em;
    }

    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding: 8.5px 10px 8.5px 10px;
      }
    }
  }
}
