.is-loading {
  background: #eee;
  background: linear-gradient(
    110deg,
    #4f4f4f0f 8%,
    #f5f5f58f 18%,
    #4f4f4f0f 33%
  );
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@function mapReverse($map) {
  $result: null;

  @if type-of($map) == 'map' {
    $keys: map-keys($map);
    $map-reversed: ();

    @for $i from length($keys) through 1 {
      $map-reversed: map-merge(
        $map-reversed,
        (
          nth($keys, $i): map-get($map, nth($keys, $i))
        )
      );
    }

    @if type-of($map-reversed) == 'map' {
      $result: $map-reversed;
    } @else {
      @warn 'There was an error reversing the order of "#{$map}"';
    }
  } @else {
    @warn '"#{$map}" is not a valid map';
  }

  @return $result;
}

@mixin angle($pseudo, $flip: false, $angle: 3deg) {
  $angleAfterSizes: (
    'xs': 5%,
    'sm': 5%,
    'md': 10%,
    'lg': 15%,
    'xl': 20%
  );

  // Possible values for $pseudo are: before, after, both
  @if $pseudo == 'before' or $pseudo == 'after' or $pseudo == 'both' {
    position: relative;
    z-index: -1;
    $selector: if($pseudo == 'both', '&:before,&:after', '&:#{$pseudo}');

    #{$selector} {
      background: white;
      content: '';
      display: block;
      height: 25%;

      @each $size, $breakAt in typographyVariables.$breakpoints {
        @media (min-width: '#{$breakAt}px') {
          height: map-get($angleAfterSizes, $size);
        }
      }

      left: 0;
      position: absolute;
      right: 0;
      z-index: -1;
      -webkit-backface-visibility: hidden; // for Chrome Windows
    }

    @if $pseudo == 'before' {
      #{$selector} {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }
    }

    @if $pseudo == 'after' {
      #{$selector} {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 100%;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }

    @if $pseudo == 'both' {
      &:before {
        top: 0;

        @if $flip {
          transform: skewY($angle * -1);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle);
          transform-origin: 100% 0;
        }
      }

      &:after {
        bottom: 0;

        @if $flip {
          transform: skewY($angle);
          transform-origin: 0 0;
        } @else {
          transform: skewY($angle * -1);
          transform-origin: 100%;
        }
      }
    }
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.MuiDataGrid-root {
  &.data-grid-with-filter {
    .MuiDataGrid-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.MuiFormControl-root {
  .MuiInputLabel-root.MuiInputLabel-shrink {
    top: -5px;
  }
  .MuiOutlinedInput-root {
    .MuiOutlinedInput-input {
      padding: 19.5px 14px 13.5px 14px;
    }
  }
}

.MuiInputBase-root {
  &.MuiFilledInput-root {
    background-color: transparent;
    border-left: solid 1px rgba(0, 0, 0, 0.18);
    border-right: solid 1px rgba(0, 0, 0, 0.18);
    border-top: solid 1px rgba(0, 0, 0, 0.18);
  }

  .Mui-disabled {
    &.MuiSelect-icon {
      display: none;
    }

    &.MuiSelect-select {
      -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
    }
  }
}

.justify-self-center {
  justify-self: center;
}

.justify-self-right {
  justify-self: center;
}

.justify-self-left {
  justify-self: center;
}

.w-100 {
  width: 100%;
}

.brand-background {
  &.wp-background {
    min-height: 750px;
    display: flex;
    //margin-bottom: 133px;
    align-items: center;
    position: relative;

    @media (min-width: 782px) {
      min-height: 650px;
    }

    .wp-block-group__inner-container {
      width: 100%;
    }

    .wp-block-columns {
      margin-bottom: 0;
    }

    &::before {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 100%;
    }
  }

  &.background-primary::before,
  &.has-primary-background-color::before {
    background-color: palette.$primaryMain;
  }

  &.background-secondary::before,
  &.has-secondary-background-color::before {
    background-color: palette.$secondaryMain;
  }

  &.background-secondary-light::before {
    background-color: palette.$secondaryLight;
  }

  &.background-trinary::before,
  &.has-trinary-background-color::before {
    background-color: palette.$trinaryMain;
  }

  &.background-concrete::before,
  &.has-concrete-background-color::before {
    background-color: palette.$concrete;
  }

  &::before {
    content: '';
    //min-height: 750px;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    -webkit-transform: skewY(-3deg);
    -moz-transform: skewY(-3deg);
    -ms-transform: skewY(-3deg);
    transform: skewY(-3deg);
    z-index: -1;

    @media (min-width: 782px) {
      //min-height: 650px;
    }
  }
}
