.course-card {
  &.course-completed {
    background-color: #f0e6fd;
  }

  .MuiCardActionArea-root {
    .MuiCircularProgress-circle {
      stroke-linecap: square;
    }
    .card-overlay {
      opacity: 0;
      transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &:hover {
      .card-overlay {
        opacity: 1;
      }

      .MuiCardActionArea-focusHighlight {
        opacity: 0.25;
      }
    }
  }
}
