.mdc-layout-grid {
  .material-design-card {
    height: 100%;
  }
}

.mdc-card {
  &:hover,
  &.mdc-card__raised {
    box-shadow: 0px 3px 5px -2px rgb(0 0 0 / 20%),
      0px 3px 8px 0px rgb(0 0 0 / 14%), 0px 1px 30px 0px rgb(0 0 0 / 12%);
    transform: scale(1.007);
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .image-top {
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
  }

  .mdc-card__media {
    img {
      bottom: 0;
      height: 100%;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .mdc-card__primary-action {
    .material-design-card__primary {
      padding: 1em;
    }

    .material-design-card__supporting-text {
      padding: 0 1em 8px;
    }

    .material-design-card__title {
      margin: 0 !important;
    }
  }

  &.single-post-card {
    .mdc-card__link,
    .mdc-card__link:hover {
      text-decoration: none !important;
    }

    .mdc-card__primary-action {
      .single-post-card__primary {
        padding: 16px;

        .single-post-card__title {
          margin: 0 !important;
        }
      }

      .single-post-card__secondary {
        padding: 0 16px 8px;
      }
      .single-post-card__secondary-text-above-media,
      .single-post-card__secondary-text-over-media {
        padding: 16px;
      }

      &:hover {
        &:before {
          opacity: 0.04;
        }
      }

      &:after,
      &:before {
        //background-color: #000;
        height: 200%;
        left: -50%;
        top: -50%;
        width: 200%;
        border-radius: 50%;
        content: '';
        opacity: 0;
        pointer-events: none;
        position: absolute;
      }

      &:not(.mdc-ripple-upgraded) {
        &:active {
          &:after {
            opacity: 0.12;
            transition-duration: 75ms;
          }
        }

        &:after {
          transition: opacity 0.15s linear;
        }
      }

      &:before {
        transition: opacity 15ms linear, background-color 15ms linear;
        z-index: 1;
      }
    }

    .material-design-card__title,
    .mdc-card,
    .mdc-card__link,
    .single-post-card__title {
      color: palette.$blackMain;
      color: var(--mdc-theme-on-surface, palette.$blackMain);
      text-decoration: none;
    }

    .mdc-card__media {
      img {
        bottom: 0;
        height: 100%;
        left: 0;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}
